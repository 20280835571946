.tda-section {
    margin: 0 0 48px;
    display: block;
}

.tda-section > h2 {

    border-top: 3px solid #1f2937;
    padding-top: 8px;
    margin: 0;

    /*font-size: 24px;*/
    /*line-height: 30px;*/
    color: #1f2937;
}

.tda-section > h2 span {
    display: inline-block;
    border-right: 2px solid #cc0000;
    padding-right: 12px;
    margin-right: 12px;
}

.tda-section--inset > h2 {
    margin: 0 24px;
}
