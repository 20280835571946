.tda-info {
    position: relative;

    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;

    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    box-sizing: border-box;

    align-content: space-between;
    align-items: center;

    color: #1f2937;
    /*
    background: #EEF4F8;
    border: 2px solid #EEF4F8;

    background: #F5F5F5;
    border: 2px solid #F5F5F5;


    */
    background: #f3f4f6;
    border: 2px solid #f3f4f6;

    cursor: default;
    margin: 32px 0;
    padding: 0 14px;
}

.tda-info--light,
.tda-info--white {
    background: #fff;
}

.tda-info--white {
    /*color: #333;*/
    padding: 0;
    border: none;
}

.tda-info__icon {
    height: 24px;
    width: 24px;
    line-height: 0;
    font-size: 0;
    overflow: hidden;
    text-align: center;
    flex: 0 0 24px;
    margin: 0 8px 0 0;
}

.tda-info__icon svg {
    fill: #0369a1;
    height: 24px;
    width: 24px;
    display: block;
    margin: 0 auto;

    -webkit-transition: fill 300ms ease;
    transition: fill 300ms ease;
}

.tda-info--icon-top {
    align-items: flex-start;
}
.tda-info--icon-top .tda-info__icon {
    margin-top: 9px;
}

.tda-info__body {
    font-size: 16px;
    line-height: 24px;
    flex: 1 auto;
    margin: 14px 8px;
}

.tda-info p {
    margin: 0;
}
.tda-info p + p {
    margin-top: 6px;
}

.tda-info ul {
    margin: 0 10px 10px;
    padding: 0 10px;
}
.tda-info li {
    list-style-type: disc;
    display: list-item;
}

.tda-info p + ul {
    margin-top: -5px;
}


.tda-info strong {
    color: #333;
    display: block;
}
.tda-info strong .light {
    font-weight: normal;
}
.tda-info:before {
    position: absolute;
    content: ' ';
    top: -2px;
    left: -2px;
    bottom: -2px;
    background: #0369a1;
    width: 4px;
    /*-webkit-border-radius: 4px;*/
    /*-moz-border-radius: 4px;*/
    /*border-radius: 4px;*/

    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.tda-info--error:before,
.tda-info--white:before {
    display: none;
}

.tda-info--alert:before {
    background: #cc0000;
}
.tda-info--alert .tda-info__icon svg {
    fill: #cc0000;
}

.tda-info--error {
    color: #fff;
    background: #cc0000;
    border-color: #CC0000;
}

.tda-info--error .tda-info__body strong {
    color: #fff;
}

.tda-info--error .tda-info__icon svg {
    fill: #fff;
}

.tda-info--error .tda-form-link {
    color: white;
}

.tda-info--error .tda-form-link:hover {
    color: black;
}

.tda-info--error a.tda-link {
    color: white;
    text-decoration-color: inherit;
}
.tda-info--error a.tda-link:hover {
    color: black;
}

.tda-info--error p + p {
    margin: 6px 0;
    padding: 6px 0;
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid white;
}
