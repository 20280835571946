/* Text */

.tda-nowrap {
  white-space: nowrap;
}
.tda-text-grey {
  color: #d1d5db;
}
.tda-text-white {
  color: white;
}
.tda-text-center {
  text-align: center;
}

/* Coupon */

.tda-text-promo-code {
  color: #CC0000;
  border: rgba(204, 0, 0, 0.4) solid 1px;
  background: rgba(204, 0, 0, 0.1);
  text-align: center;
  padding: 0 0.5rem;
  letter-spacing: 0.1em;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

/* List */

.tda-list {
  margin: 0;
  padding: 0;
  border-bottom: 2px solid #e5e7eb;
}

.tda-list li {
  padding: 12px 0;
  list-style: none;
  border-top: 2px solid #e5e7eb;
}

/* Unordered List */

.tda-ul {
  margin: 0;
  padding: 0 0 0 18px;
}

.tda-ul li {
  padding: 0;
  margin: 0;
}
.tda-ul li + li {
  padding: 0;
  margin: 12px 0 0;
}

/* Paddings */

.tda-pb-0 {
  padding-bottom: 0;
}

.tda-pb-2 {
  padding-bottom: 8px;
}
.tda-pl-3 {
  padding-left: 12px;
}

/* Margins */

.tda-m-0 {
  margin: 0;
}
.tda-mb-1 {
  margin-bottom: 4px;
}
.tda-mb-2 {
  margin-bottom: 8px;
}
.tda-mb-4 {
  margin-bottom: 16px;
}
.tda-mb-6 {
  margin-bottom: 24px;
}
.tda-my-6 {
  margin-bottom: 24px;
}

.tda-m-1 {
  margin: 12px !important;
}
.tda-m-2 {
  margin: 24px !important;
}

.tda-mt-1 {
  margin-top: 12px !important;
}
.tda-mt-2 {
  margin-top: 24px !important;
}

.tda-m--v1 {
  margin: 12px 0 !important;
}
.tda-m--v2 {
  margin: 24px 0 !important;
}

.tda-m--x1 {
  margin: 0 12px !important;
}
.tda-m--x2 {
  margin: 0 24px !important;
}

/* Width */

.tda-width--full {
  display: block;
  width: 100%;
}

.td-show-if-alone {
  display: none;
}
.td-show-if-alone:last-child {
  display: block;
}

/* Flex */

.tda-flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: normal;
}

/*.tda-flex-row {*/
/*  display: flex;*/
/*  flex-direction: row;*/
/*  flex-wrap: nowrap;*/
/*  justify-content: flex-start;*/
/*  align-items: stretch;*/
/*  align-content: normal;*/
/*}*/

/* Headers */

.tda-kicker {
  color: #cc0000;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

@media only screen and (max-width: 425px) {
  .td-hide--m {
    display: none;
  }
}

