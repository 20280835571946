.tda-input {
    display: block;
    box-sizing: border-box;
    background-color: #fff;
    /*background-color: #fbfcfc;*/

    border-radius: 4px;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;

    font-size: 16px;
    line-height: 24px;
    padding: 20px 14px;
    margin: 0;
    border: 2px solid #BDBDBD;
    border: 2px solid #d1d5db;

    /*border-color: #e5e7eb;*/
    /*border-color: #aac4c7;*/
    /*border-color: #333;*/
    /*border-color: #699;*/

    height: 48px;
    width: 100%;

    box-shadow: 0 1px 3px 0 rgba(3, 105, 161, 0);

}
textarea.tda-input {
    padding: 10px 14px;
    height: auto;
    min-height: 48px;
}

.tda-input:focus,
.tda-input:active {
    outline: none;
    border-color: #0369a1;
    /*border-color: #333;*/

    box-shadow: 0 4px 6px 0 rgba(3, 105, 161, 0.2);
}

.tda-form-field--has-errors .tda-input,
.tda-input--error {
    border-color: #c00;
}

.tda-form-field--has-errors .tda-input:active,
.tda-input--error:active,
.tda-input:active,
.tda-input:focus {
    border-color: #0369a1;
    /*border-color: #333;*/
}

.tda-input::-webkit-input-placeholder {
    color: #d1d5db;
    opacity: 1;
}

.tda-input--select {
    position: relative;
    padding: 0;
    border: 0;
    cursor: pointer;
}

.tda-input--select select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    display: block;
    padding: 4px 12px;
    font-size: 16px;
    line-height: 24px;
    font-family: inherit;
    font-weight: normal;
    color: #333;
    width: 100%;
    max-width: 100%;
    margin: 0;
    border-radius: 3px;

    border: 2px solid #d1d5db;

    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0iYmxhY2siIHdpZHRoPSIxOHB4IiBoZWlnaHQ9IjE4cHgiPjxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiIGZpbGw9Im5vbmUiLz48cGF0aCBkPSJNNyAxMGw1IDUgNS01eiIvPjwvc3ZnPg==);
    background-repeat: no-repeat;
    background-position: center right;
    cursor: pointer;
}

/*.tda-input--select svg {*/
/*    position: absolute;*/
/*    top: 6px;*/
/*    right: 6px;*/
/*    height: 24px;*/
/*    fill: #699;*/
/*}*/