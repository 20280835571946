
.tda-checkout h2,
.tda-checkout h3 {
    font-size: 24px;
    line-height: 24px;
}
.tda-checkout h3 {
    font-weight: normal;
    margin: 24px 0 16px;
}

.tda-checkout-price {
    font-size: 24px;
    line-height: 24px;
}

.tda-checkout-summary {
    border-top: 2px solid #e5e7eb;
    border-bottom: 2px solid #e5e7eb;
    margin: 32px 0;
    padding: 12px 0;
}
.tda-checkout-summary > div:first-child {
    flex-grow: 2;
}
.tda-checkout-summary > div + div {
    text-align: right;
    flex-grow: 1;
    flex-shrink: 1;
    white-space: nowrap;
}

@media screen and (max-width: 760px) {
    #tda-checkout-plans.tda-flex-row {
        display: block;
    }
    #tda-checkout-currency.tda-flex-row {
        flex-wrap: wrap;
    }
    #tda-checkout-currency .tda-checkout-option {
        width: 50%;
        flex-basis: 50%;
    }
}
@media screen and (min-width: 760px) and (max-width: 870px) {
    #tda-checkout-plans .tda-checkout-option__label b span {
        display: block;
    }
}