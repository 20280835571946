.tda-link,
.tda-link:visited {
  color: #0369a1;
  text-decoration: underline;
  /*text-decoration-color: #d6e4ef;*/
  /*text-decoration-thickness: 2px;*/
  text-underline-offset: 0.2rem;
  cursor:pointer;
}
.tda-link:hover {
  color: #cc0000;
  text-decoration-color: #cc0000;
}
.tda-link.tda-text-white:hover {
  color: white;
  text-decoration-color: white;
}