.tda-gated.tda-gated--unlocked + aside.tda-gated-appendix,
.tda-gated.tda-gated--in + aside.tda-gated-appendix .tda-gated-login,
.tda-gated--in .tda-gated-login {
    display: none;
}
.tda-gated.tda-gated--locked {
    position: relative;
    max-height: 240px;
    overflow: hidden;
    pointer-events: none;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
.tda-gated.tda-gated--locked:after {
    position: absolute;
    content: ' ';
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    bottom: 0;
    background: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1));
}
.tda-gated.tda-gated--locked > aside {
    display: none;
}
#tda-gated-counter,
.tda-gated-appendix {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
}
.tda-gated-box {
    color: #333;
    background: #f3f4f6;
    overflow: hidden;
    margin: 24px 0;
}
.tda-gated-box__ribbon {
    color: white;
    padding: 11px 24px 13px 24px;
    background: #cc0000;
}
.tda-gated-box__main,
.tda-gated-box__footer {
    margin: 24px;
}
.tda-gated-box p,
.tda-gated-box .tda-button {
    margin: 12px 0;
}
.tda-gated-box h2 {
    font-size: 40px;
    line-height: 48px;
    margin: 0;
}
.tda-gated-box__main p {
    font-size: 28px;
    line-height: 36px;
}