.tda-checkout-options * {
    margin: 0;
    box-sizing: border-box;
}

.tda-checkout-options {
    font-size: 16px;
    line-height: 20px;
    margin: -8px;
}
.tda-checkout-option {
    position: relative;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: normal;

    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 33.33%;
    align-self: auto;
    padding: 8px;
}


.tda-checkout-option input {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    opacity: 0;
    z-index: -1;
}

.tda-checkout-option__label {
    position: relative;
    /*display: flex;*/
    /*width: 100%;*/
    flex-grow: 1;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    border: 2px solid rgb(3, 105, 161, 0.2);
    background: rgb(3, 105, 161, 0.025);
    padding: 16px 16px 16px 58px;
    z-index: 0;

    transition: border,background 400ms;
}

.tda-checkout-option__tick {
    position: absolute;
    /*top: 31px;*/
    /*left: 24px;*/
    top: 50%;
    left: 24px;
    margin-top: -16px;
    z-index: 1;
    width: 32px;
    height: 32px;

    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;

    color: rgb(255, 255, 255, 0);
    border: 2px solid rgb(3, 105, 161, 0.2);
    background: rgb(3, 105, 161, 0.05);

    transition: color,border,background 400ms;
}

.tda-checkout-option__tick svg {
    margin: 2px;
}
.tda-checkout-option--checked .tda-checkout-option__label {
    background: rgb(3, 105, 161, 0.2);
    border-color: rgb(3, 105, 161, 1);
}
.tda-checkout-option--checked .tda-checkout-option__tick {
    color: rgb(255, 255, 255, 1);
    background: rgb(3, 105, 161, 1);
    border-color: rgb(3, 105, 161, 1);
}

.tda-checkout-option label > * {
    display: block;
}

.tda-checkout-option--sm .tda-checkout-option__tick {
    left: 20px;
    width: 18px;
    height: 18px;
    margin-top: -9px;

}
.tda-checkout-option--sm .tda-checkout-option__tick svg {
    margin: 1px;
    width: 12px;
    height: 12px;
}
.tda-checkout-option--sm .tda-checkout-option__label {
    padding: 8px 8px 8px 36px;
}

