.tda-button,
button.tda-button {

    font-size: 14px;
    line-height: 16px;

    display: block;
    color: #fff;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.1em;
    background: #0369a1;

    padding: 14px 14px;
    box-sizing: border-box;

    border: 2px solid #0369a1;

    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;

    cursor: pointer;
}
.tda-button--accent,
button.tda-button--accent {
    background: #cc0000;
}
.tda-button--ghost,
button.tda-button--ghost {
    color: #0369a1;
    background: rgba(3, 105, 161, 0.2);
    border-color: rgba(3, 105, 161, 0);

    /*background: #d6e4ef;*/
    /*border-color: #d6e4ef;*/
}

.tda-button:hover,
.tda-button:focus,
.tda-button:active {
    color: #fff;
    background: #1f2937;
    box-shadow: 0 1px 3px 0 rgb(0, 0, 0, 0.1);
    outline: none;
}
.tda-button:focus {
    border-color: #0369a1;
}
.tda-button:hover,
.tda-button:active {
    border-color: #1f2937;
}


.tda-button:disabled {
    color: #d1d5db;
    /*background: #e5e7eb;*/
    /*border-color: #e5e7eb;*/
    background: #f3f4f6;
    border-color: #f3f4f6;
    box-shadow: none;
    cursor: not-allowed;
}


.tda-button.tda-button--loading,
.tda-button.tda-button--loading:disabled {
    color: #fff;
    background: #1f2937;
    border-color: #1f2937;
    box-shadow: none;
    cursor: progress;
}
