/* ==========================================================================
   Field
   ========================================================================== */

.tda-form-field {
    position: relative;
    padding: 0 0 24px;

    display: block;
    box-sizing: border-box;

    -webkit-flex: 2 100%;
    -ms-flex: 2 100%;
    flex: 2 100%;

    /*width: 100%;*/
}

.tda-form-field--50w {
    flex: 1 50%;
    width: 50%;
}

/* ==========================================================================
   Field - Label
   ========================================================================== */

.tda-form-field__label {
    font-weight: bold;
    margin: 0 0 6px;
    display: block;
}

.tda-form-field__label span {
    font-weight: normal;
    color: #d1d5db;
}

/* ==========================================================================
   Field  - Errors
   ========================================================================== */

.tda-form-field__errors {
    font-size: 14px;
    line-height: 16px;
    color: #c00;
    margin: 0;
    display: none;
}

.tda-form-field--has-errors .tda-form-field__errors {
    display: block;
}

.tda-form-field-error {
    margin: 8px 0 0;
}

.tda-form-field-error p {
    margin: 0;
}

/* ==========================================================================
   Field  - Description
   ========================================================================== */

.tda-form-field__description {
    font-size: 14px;
    line-height: 16px;
    color: #333;
    margin: 8px 0 0;
    display: block;
}

.tda-form-field__description p {
    margin: 0;
}

/* ==========================================================================
   Field  - Media
   ========================================================================== */

@media screen and (max-width: 425px) {

    .tda-form-field--50w {
        -webkit-flex: 2 100%;
        -ms-flex: 2 100%;
        flex: 2 100%;
    }

}