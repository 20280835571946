.tda-modal-backdrop {
    display: none;
    position: fixed;
    background: #000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 102;

    opacity: 0;
    transition: opacity .4s cubic-bezier(.25,.46,.45,.94);
}
.tda-modal.tda-modal--active .tda-modal-backdrop {
    display: block;
}
.tda-modal.tda-modal--show .tda-modal-backdrop {
    opacity: 0.5;
}

.tda-modal-window {
    display: none;
    position: fixed;
    left: 10%;
    top: 100%;
    width: 80%;
    height: 80%;
    z-index: 103;
    box-shadow: 0 0 24px 0 rgb(0 0 0 / 25%);
    border-radius: 10px 10px 10px 10px;
    -moz-border-radius: 10px 10px 10px 10px;
    -webkit-border-radius: 10px 10px 10px 10px;
    border: 0 solid #fff;
    overflow: hidden;
    /*background: #EEF4F8;*/
    background: #f3f4f6;
    transition: top .2s ease-in-out;
}

@media screen and (min-width: 1024px) {
    .tda-modal-window {
        left: 25%;
        width: 50%;
    }
}

.tda-modal.tda-modal--active .tda-modal-window {
    display: block;
}
.tda-modal.tda-modal--show .tda-modal-window {
    top: 10%;
}

.tda-modal-esc {
    position: absolute;
    display: block;
    top: 0;
    right:0;
    height: 48px;
    width: 48px;
    z-index: 101;
    opacity: .5;
    cursor: pointer;
    transition: opacity .2s ease-in-out;
}
.tda-modal-esc svg {
    display: block;
    margin:12px;
    height: 24px;
    width: 24px;
}
.tda-modal-esc:hover {
    opacity: 1;
}

.tda-modal-content {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-y: auto!important;
    overflow-x: hidden!important;
    -webkit-overflow-scrolling: touch;
}

.tda-modal-content section,
.tda-modal-content footer {
    display: block;
    padding: 48px;
}
.tda-modal-content section {
    background: white;
    /*border-bottom: 1px solid #d1d5db;*/
}

.tda-modal-title {
    /*color: #1f2937;*/
    display: block;
    font-size: 24px;
    line-height: 30px;
    font-weight: bold;
    margin: 0 0 24px;
}

.tda-modal-title span {
    font-weight: normal;
    display: block;
}

.tda-modal-help > div {
    display: block;
    margin: 0 0 24px;
}

.tda-modal-footer {
    padding: 12px 24px 0;
    background:#f3f4f6
}

.tda-modal--no-scroll {

}
.tda-modal--no-scroll .tda-modal-window {
    /*position: absolute;*/
    height: auto;
    max-height: 80%;
    overflow-y: auto;
}
.tda-modal--no-scroll .tda-modal-content {
    position: relative;
}